// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactFlowNodeType, ReactFlowNodeTypes } from '../types/ReactFlowNode/ReactFlowNode';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import NodeTemplate from './NodeTemplate';

interface CustomNodeProps extends NodeProps {
    data: { label: string; type: ReactFlowNodeType; question?: string };
}

const KeywordMapBookkeepingAccountNode = (props: CustomNodeProps) => {
    return (
        <>
            

            <NodeTemplate  active={props.data.active} node={props}>

            </NodeTemplate>
            

            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
        </>
    );
};

export default KeywordMapBookkeepingAccountNode;
