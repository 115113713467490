// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactFlowNodeType, ReactFlowNodeTypes } from '../types/ReactFlowNode/ReactFlowNode';
import TopicIcon from '@mui/icons-material/Topic';
import NodeTemplate from './NodeTemplate';
import QuestionNode from './QuestionNode';

interface CustomNodeProps extends NodeProps {
    data: { label: string; type: ReactFlowNodeType; question?: string };
}

const BookkeepingAccountNode = (props: CustomNodeProps) => {

    return <QuestionNode {...props} />;
    
};

export default BookkeepingAccountNode;
