// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactFlowNodeType, ReactFlowNodeTypes } from '../types/ReactFlowNode/ReactFlowNode';
import TopicIcon from '@mui/icons-material/Topic';
import NodeTemplate from './NodeTemplate';
import QuestionNode from './QuestionNode';

interface CustomNodeProps extends NodeProps {
    data: { label: string; type: ReactFlowNodeType; question?: string };
}

const AlertNode = (props: CustomNodeProps) => {
    
    return <QuestionNode {...props} />;
    return (
        <>
            

            <NodeTemplate  active={props.data.active}  node={props}>
                 <TopicIcon fontSize={'medium'}></TopicIcon> {props.data.label}
                {props.data.question && (
                    <p
                        style={{
                            fontWeight: 400,
                            fontSize: '0.75rem',
                            margin: '0.5rem 0 0 0',
                            padding: 0,
                            maxWidth: '15rem',
                        }}
                    >
                        {props.data.question}
                    </p>
                )}
            </NodeTemplate>

            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
        </>
    );
};

export default AlertNode;
