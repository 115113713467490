import { Box, TextField } from '@mui/material';
import { memo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Handle, Position, useNodeId, useViewport } from 'reactflow';
import useNodesStateSynced from '../../collaboration/useNodesStateSynced';

const MIN_DIMENSION = 200;

const ResizableNodeSelected = ({ data, selected }) => {
    
    const currentWidth = Math.max(data.width || 0, MIN_DIMENSION)
    const currentHeight = Math.max(data.height || 0, MIN_DIMENSION)
  
  const [labelValue, setLabelValue] = useState(data.label);
  const [isDragging, setIsDragging] = useState(false);
  const [activeHandle, setActiveHandle] = useState(null);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [initialWidth, setInitialWidth] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [initialPositionX, setInitialPositionX] = useState(0);
  const [initialPositionY, setInitialPositionY] = useState(0);

  const nodeId = useNodeId()
  const { x, y, zoom } = useViewport();
  const { flowId } = useParams();
  
  const [reactFlowNodes, setReactFlowNodes, onNodeChanges, nodesLoaded] = useNodesStateSynced({
      flowId,
  });

  const [node, setNode] = useState(null);

  useEffect(() => {
    const currentNode = reactFlowNodes.find(n => n.id === nodeId);
    setNode(currentNode);
  }, [nodeId, reactFlowNodes]);

  useEffect(() => {
    setLabelValue(data.label);
  }, [data.label]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLabelValue(newValue);
    data.label = newValue
  };

  const handleMouseDown = useCallback((e, position) => {
    console.log("handleMouseDown")
    if (node) {
      setInitialWidth(currentWidth);
      setInitialHeight(currentHeight);
      setInitialPositionX(node.position.x);
      setInitialPositionY(node.position.y);
    }
    setDragStartX(e.clientX);
    setDragStartY(e.clientY);
    setIsDragging(true);
    setActiveHandle(position);
    e.stopPropagation();
  }, [node, currentWidth, currentHeight]);

  const handleMouseMove = useCallback((e) => {
    if (!isDragging || !activeHandle || !node) return;

    e.stopPropagation();
    let hasChanged = false;

    if (activeHandle?.includes('bottom')) {
      const deltaY = e.clientY - dragStartY;
      const scaledDeltaY = deltaY / zoom;
      
      const newHeight = Math.max(initialHeight + scaledDeltaY, MIN_DIMENSION);
      const newY = initialPositionY + (newHeight - initialHeight)/2;
      
      if (node.data.height !== newHeight || node.position.y !== newY) {
        node.data.height = newHeight;
        node.position.y = newY;
        hasChanged = true;
      }
    } else if (activeHandle?.includes('top')) {
      const deltaY = dragStartY - e.clientY;
      const scaledDeltaY = deltaY / zoom;
      
      const newHeight = Math.max(initialHeight + scaledDeltaY, MIN_DIMENSION);
      const newY = initialPositionY - (newHeight - initialHeight)/2;
      
      if (node.data.height !== newHeight || node.position.y !== newY) {
        node.data.height = newHeight;
        node.position.y = newY;
        hasChanged = true;
      }
    }

    if (activeHandle?.includes('right')) {
      const deltaX = e.clientX - dragStartX;
      const scaledDeltaX = deltaX / zoom;
      
      const newWidth = Math.max(initialWidth + scaledDeltaX, MIN_DIMENSION);
      const newX = initialPositionX + (newWidth - initialWidth)/2;
      
      if (node.data.width !== newWidth || node.position.x !== newX) {
        node.data.width = newWidth;
        node.position.x = newX;
        hasChanged = true;
      }
    }

    if (hasChanged) {
      setReactFlowNodes([...reactFlowNodes]);
    }
  }, [isDragging, activeHandle, dragStartX, dragStartY, initialWidth, initialHeight, 
      zoom, node, initialPositionX, initialPositionY]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setActiveHandle(null);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);


  return (
    <Box className="group-node" style={{
      zIndex: 0,
      background: "transparent",
      width: currentWidth,
      height: currentHeight,
      position: 'relative'
    }} >
      {selected && (
        <>
          <div
            className="resize-handle top-right"
            onMouseDownCapture={(e) => handleMouseDown(e, 'top-right')}
            
            style={{
              position: 'absolute',
              top: -6,
              right: -6,
              width: 12,
              height: 12,
              background: '#888',
              borderRadius: 6,
              cursor: 'ne-resize',
              zIndex: 1
            }}
          />
          <div
            className="resize-handle bottom-right"
            onMouseDownCapture={(e) => handleMouseDown(e, 'bottom-right')}
            style={{
              position: 'absolute',
              bottom: -6,
              right: -6,
              width: 12,
              height: 12,
              background: '#888',
              borderRadius: 6,
              cursor: 'se-resize'
            }}
          />
        </>
      )}
      <div style={{
        position: "absolute", 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        zIndex: 0,
        border: "solid 1px #888",
        borderRadius: "10px",
        background: `repeating-linear-gradient(
          45deg,
          rgba(200, 200, 200, 0.3),
          rgba(200, 200, 200, 0.3) 10px,
          rgba(150, 150, 150, 0.3) 10px,
          rgba(150, 150, 150, 0.3) 20px
        )`
      }}/>
      <TextField
        value={labelValue}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Backspace') {
            e.stopPropagation();
          }
        }}
        onKeyUp={(e) => {
          if (e.key === 'Backspace') {
            e.stopPropagation(); 
          }
        }}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          style: {
            padding: 10,
            borderRadius: 10,
            background: "#ffffff77", 
            fontSize: "inherit",
            fontFamily: "Helvetica Neue",
             margin: 1
          }
        }}
      />
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      
    </Box>
  );
};

export default memo(ResizableNodeSelected);