// @ts-nocheck

import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';

import { YDocProvider, fetchUser } from '../collaboration/useYDoc';
import useThreadStateSynced from '../collaboration/useThreadStateSynced';
import { Box, Button, Chip, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { set } from 'lodash';
import FlowchartEditor from '../flowchart-editor/FlowchartEditor';
import { ReactFlowProvider } from 'reactflow';
import HeaderBar from '../header-bar/HeaderBar';
import useActiveUser from '../collaboration/useActiveUser';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';
import { parser as jsonlParser } from 'stream-json/jsonl/Parser';

const uploadedImages = {};

function uploadImage(path, file, uploadId) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append('file', file, file.name);

    // Append other form fields
    // formData.append('title', title);

    // Create and configure an XMLHttpRequest
    const xhr = new XMLHttpRequest();
    xhr.open('POST', path, true);
    xhr.setRequestHeader('Upload-Id', uploadId);
    xhr.setRequestHeader('Upload-Extension', file.name.split('.').pop());

    xhr.onload = function () {
      if (xhr.status === 200) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject({ status: xhr.statusText, text: xhr.responseText });
      }
    };

    xhr.onerror = function () {
      console.error('Error:', xhr.statusText);
    };

    // Send the form data
    xhr.send(formData);
  });
}

const AssistantRunView = ({ threadId }: { threadId: string }) => {
  const navigate = useNavigate();
  // console.log("threadId in assistantRunView", threadId)
  const [threadData, setThreadData] = useThreadStateSynced({ threadId });

  const messagesRef = useRef<HTMLDivElement>(null);

  const [thread, setThread] = useState<any>(null);
  const { assistantId } = useParams();
  const { activeUser } = useActiveUser();

  const [activeOrganisation, setActiveOrganisation] = useActiveOrganisation();

  useEffect(() => {
    const fetchThread = async () => {
      const response = await fetch(`/api/thread/${threadId}`, {
        headers: { 'X-Organisation-Id': activeOrganisation.organizationId },
      });
      const thread = await response.json();
      setThread(thread);
    };
    fetchThread();
  }, []);

  useEffect(() => {
    if (messagesRef.current) messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
  }, [threadData]);

  const [fetchingResponse, setFetchingResponse] = useState(false);

  const onOptionClicked = async (message: string) => {
    addMessage({
      role: 'user',
      content: [
        {
          type: 'text',
          text: message,
        },
      ],
    });
  };

  const addMessage = async (message: any) => {
    setFetchingResponse(true);

    const newThreadData = threadData ? { ...threadData } : { thread: {} };

    if (!newThreadData?.thread) {
      newThreadData.thread = { messages: [] };
    }

    if (!newThreadData.thread.messages) {
      newThreadData.thread.messages = [];
    }

    newThreadData.thread.messages.push(message);

    setThreadData(newThreadData);

    const response = await fetch(`/api${window.location.pathname}`, {
      method: 'POST',
      body: JSON.stringify(message),
      headers: {
        'Content-Type': 'application/json',
        'X-Organisation-Id': activeOrganisation.organizationId,
      },
    });

    const messageIndex = newThreadData.thread.messages.length;

    setThreadData({ ...newThreadData });

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let done = false;

    let unprocessedText = '';

    // Process the stream
    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;

      unprocessedText += decoder.decode(value, { stream: true });

      const jsonObjects = [];
      const lines = unprocessedText.split('\n');
      let remainingLines = [];

      for (const line of lines) {
        if (line.trim() === '') continue; // Skip empty lines

        try {
          const jsonObject = JSON.parse(line);
          jsonObjects.push(jsonObject);
        } catch (error) {
          // If parsing fails, add the line to remainingLines
          remainingLines.push(line);
        }
      }

      // Update unprocessedText to contain only the unused lines
      unprocessedText = remainingLines.join('\n');

      const threadMessages = newThreadData.thread.messages;

      // Process the parsed JSON objects
      for (const object of jsonObjects) {
        console.log(object.event);

        if (object.event == 'thread.message.created') {
          if (!object.data.content || object.data.content.length == 0)
            threadMessages.push({
              role: 'assistant',
              content: [{ type: 'text', text: '' }],
            });
          else {
            threadMessages.push(object.data);
          }
          setThreadData({ ...newThreadData });
        } else if (object.event == 'thread.message.delta') {
          console.log('thread.message.delta', object.data.delta.content[0].text.value);
          threadMessages[threadMessages.length - 1].content[0].text +=
            object.data.delta.content[0].text.value;
          setThreadData({ ...newThreadData });
        } else if (object.event == 'thread.message.completed') {
          //                     setFetchingResponse(false);
        } else if (object.event == 'thread.run.completed') {
          setThreadData({ ...newThreadData });
          setFetchingResponse(false);
        }
      }
    }

    //  setFetchingResponse(false);
  };

  const messageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  };

  const messageStyle = {
    borderRadius: '10px',
    padding: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    margin: '10px',
    background: '#f0f0f0',
    display: 'flex',
    width: 'fit-content',
    maxWidth: '90%',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    border: 'solid 1px #d0d0d0',
  };

  const userMessageStyle = Object.assign({}, messageStyle, {
    alignSelf: 'flex-end',
    background: '#c7c7c7',
  });

  const assistantMessageStyle = Object.assign({}, messageStyle, {
    alignSelf: 'flex-start',
    background: '#f7f7f7',
  });
  console.log('threadData', threadData);
  const [dragging, setDragging] = useState(false);
  //

  return (
    <ReactFlowProvider>
      <HeaderBar />
      <Box
        sx={{
          position: 'fixed',
          top: '90px',
          left: 0,
          width: '100%',
          height: 'calc(100vh - 90px)',
        }}
      >
        <Stack className="App" direction="row" sx={{ height: 'calc(100vh - 90px)', width: '100%' }}>
          <Box sx={{ width: '50%', height: '100%' }}>
            {thread && <FlowchartEditor flowId={thread.metadata.process_id} />}
          </Box>{' '}
          <Box sx={{ width: '50%' }}>
            <FileUploader
              onDraggingStateChange={(isDragging) => {
                setDragging(isDragging);
              }}
              handleChange={(file) => {
                if (file) {
                  const reader = new FileReader();

                  reader.onload = async function (event) {
                    const base64String = event.target.result;
                    const uploadId = Math.random().toString(36).substring(7);
                    uploadedImages[uploadId] = base64String;

                    const messageId = Math.random().toString(36).substring(7);

                    const response = await uploadImage(
                      `/api/organisation/${activeOrganisation.organizationId}/files`,
                      file,
                      uploadId
                    );

                    addMessage({
                      role: 'user',
                      content: [
                        {
                          type: 'image_url',
                          image_url: {
                            url: response.url,
                            detail: 'high',
                            type: file.type,
                            name: file.name,
                            uploadId,
                          },
                        },
                      ],
                    });
                    console.log('Base64 String:', base64String);
                  };

                  reader.onerror = function (error) {
                    console.error('Error reading file:', error);
                  };

                  reader.readAsDataURL(file);
                }
              }}
              disabled={!dragging}
              children={
                <Container maxWidth="lg">
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    sx={{
                      height: 'calc(100vh - 90px)',
                      display: 'flex',
                      flexDirection: 'column',
                      // justifyContent: "center",
                      //alignItems: "center",
                    }}
                  >
                    <Box
                      ref={messagesRef}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 140px)',
                      }}
                    >
                      {(threadData?.thread?.messages || []).map(({ role, content }, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            {role == 'assistant' && (
                              <Box
                                sx={{
                                  borderRadius: '20px',
                                  alignSelf: 'flex-start',
                                  marginTop: '10px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                  display: 'flex',
                                  border: 'solid 1px grey',
                                  width: '34px',
                                  height: '34px',
                                }}
                              >
                                <Typography variant="body1">F</Typography>
                              </Box>
                            )}
                            <Box sx={messageContainerStyle}>
                              <Box
                                style={role == 'user' ? userMessageStyle : assistantMessageStyle}
                              >
                                {content.map((contentItem, contentIndex) =>
                                  contentItem.type == 'image_url' ? (
                                    contentItem.image_url.url.endsWith('.pdf') ? (
                                      <iframe
                                        src={`${contentItem.image_url.url.replace(
                                          's3://',
                                          '/api/blob/'
                                        )}?redirect=true#toolbar=0&navpanes=0`}
                                        style={{
                                          width: '320px',
                                          height: '480px',
                                        }}
                                      ></iframe>
                                    ) : (
                                      <img
                                        key={contentIndex}
                                        src={`${contentItem.image_url.url.replace(
                                          's3://',
                                          '/api/blob/'
                                        )}?redirect=true`}
                                        style={{
                                          maxWidth: '100%',
                                        }}
                                      />
                                    )
                                  ) : contentItem.type === 'option' ? (
                                    <Chip
                                      key={contentIndex}
                                      label={contentItem.option}
                                      onClick={() => onOptionClicked(contentItem.option)}
                                      sx={{ margin: '4px' }}
                                    />
                                  ) : (
                                    <Typography key={contentIndex} variant="body1">
                                      {contentItem.text.split('\n').map((line) => {
                                        const parts = line.split(/(\*\*.*?\*\*)/g);
                                        return (
                                          <>
                                            {parts.map((part) =>
                                              part.startsWith('**') && part.endsWith('**') ? (
                                                <strong>{part.slice(2, -2)}</strong>
                                              ) : (
                                                part
                                              )
                                            )}
                                            <br />
                                          </>
                                        );
                                      })}
                                    </Typography>
                                  )
                                )}
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}

                      <Button
                        variant={'contained'}
                        sx={{
                          width: '300px',
                          alignSelf: 'center',
                          marginTop: '10px',
                        }}
                        onClick={() => {
                          navigate(`/assistant/${assistantId}/test`);
                        }}
                      >
                        Restart
                      </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexGrow: 0 }}>
                      <TextField
                        sx={{ width: '100%', margin: '10px' }}
                        placeholder="Type a message"
                        onKeyDown={(event) => {
                          if (event.keyCode == 13 && !fetchingResponse) {
                            addMessage({
                              role: 'user',
                              content: [
                                {
                                  type: 'text',
                                  text: event.target.value,
                                },
                              ],
                            });
                            event.target.value = '';
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Container>
              }
            />
          </Box>
        </Stack>
      </Box>
    </ReactFlowProvider>
  );
};

export default AssistantRunView;
