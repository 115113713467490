// @ts-nocheck

import React, { Suspense, lazy, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  useLoaderData,
  useNavigate,
  useParams,
  Navigate, // Added import for Navigate
} from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { YDocProvider } from './collaboration/useYDoc';

import './index.css';
import AssistantRunView from './assistant-run';
import VoucherPage from './pages/VoucherPage';
import OrganisationSettingsPage from './pages/OrganisationSettingsPage';
import useActiveOrganisation from './collaboration/useActiveOrganisation';
import CustomersPage from './pages/CustomersPage'; // New import
import useActiveUser from './collaboration/useActiveUser';
import LoginPage from './pages/LoginPage';
import ChartOfAccountsPage from './pages/ChartOfAccountsPage';
import ReportsPage from './pages/ReportsPage';
import { LedgerPage } from './pages/LedgerPage';
import VoucherDetailsPage from './pages/VoucherDetailsPage';

const FlowApp = lazy(() => import('./App'));
const FileUpload = lazy(() => import('./run/upload/FileUpload'));
const KeywordMapApp = lazy(() => import('./keyword-map/KeywordMapApp'));
const Dashboard = lazy(() => import('./dashboard/App'));
const AssistantEditor = lazy(() => import('./assistant-editor/AssistantEditor'));
const TasksPage = lazy(() => import('./pages/TasksPage'));

const TestRedirect = () => {
  const navigate = useNavigate();

  const [activeOrganisation, setActiveOrganisation] = useActiveOrganisation();

  const params = useParams();
  const openNewThread = async () => {
    const response = await fetch(`/api/assistant/${params.id}/threads`, {
      method: 'POST',
      headers: {
        'X-Organisation-Id': activeOrganisation.organizationId,
      },
    });

    const body = await response.json();
    const url = window.location.pathname.replace('/test', `/threads/${body.id}`);

    navigate(url);
  };

  useEffect(() => {
    openNewThread();
  }, []);

  return <div></div>;
};

const RunLoader = () => {
  const { threadId } = useParams();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AssistantRunView threadId={threadId} />
    </Suspense>
  );
};

const AssistantLoader = () => {
  const { assistantId } = useParams();

  console.log(assistantId);

  if (!assistantId) {
    return <div>loading...</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <YDocProvider docId={assistantId}>
        <AssistantEditor assistantId={assistantId} />
      </YDocProvider>
    </Suspense>
  );
};

const FlowLoader = () => {
  const { flowId } = useParams();

  return (
    <ReactFlowProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <FlowApp flowId={flowId} />
      </Suspense>
    </ReactFlowProvider>
  );
};
const VoucherPageLoader = () => {
  return <VoucherPage />;
};

const DashboardLoader = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Dashboard />
    </Suspense>
  );
};

const WordMapLoader = () => {
  const { wordMapId } = useParams();
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <KeywordMapApp keywordMapId={wordMapId as string} />
    </Suspense>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/dashboard" replace />,
  },

  {
    path: '/flow/:flowId',
    element: <FlowLoader />,
  },
  {
    path: '/flow/:id/run',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <FileUpload />
      </Suspense>
    ),
  },
  /*    {
        path: '/flow/:flowId/run/:runId',
        element: (
            <Suspense fallback={<div>Loading...</div>}>
                <YDocProvider docId={window.location.pathname.split('/')[4]}>
                    <RunViewer />
                </YDocProvider>
            </Suspense>
        ),
    },
    
    {
        path: '/flow/:flowId/run/:runId/chat',
        element: (
            <Suspense fallback={<div>Loading...</div>}>
                <YDocProvider docId={window.location.pathname.split('/')[4]}>
                    <RunViewerChat />
                </YDocProvider>
            </Suspense>
        ),
    },*/
  { path: '/settings', element: <OrganisationSettingsPage /> },
  {
    path: '/history',
    element: <VoucherPageLoader />,
  },
  {
    path: '/word-map/:wordMapId',

    element: <WordMapLoader />,
  },
  {
    path: '/',
    element: <DashboardLoader />,
  },
  {
    path: '/dashboard',
    element: <DashboardLoader />,
  },
  {
    path: '/dashboard/:currentPage',
    element: <DashboardLoader />,
  },
  {
    path: '/assistant/:assistantId/threads/:threadId',
    element: <RunLoader />,
  },
  {
    path: '/assistant/:assistantId',
    element: <AssistantLoader />,
  },
  {
    path: '/assistant/:id/test',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <TestRedirect />
      </Suspense>
    ),
  },
  {
    path: '/tasks',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <TasksPage />
      </Suspense>
    ),
  },
  {
    path: '/customers',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CustomersPage />
      </Suspense>
    ),
  },
  {
    path: '/chart-of-accounts',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ChartOfAccountsPage />
      </Suspense>
    ),
  },
  {
    path: '/reports',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ReportsPage />
      </Suspense>
    ),
  },
  {
    path: 'ledger',
    element: <LedgerPage />,
  },
  {
    path: 'ledger/vouchers/:voucherId',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <VoucherDetailsPage />
      </Suspense>
    ),
  },
]);

const loginRouter = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/login" replace />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/signup',
    element: <LoginPage />,
  },
  {
    path: '*',
    element: (props) => {
      const isAjax = props.location.state && props.location.state.isAjax;
      if (!isAjax) {
        return <Navigate to="/login" replace />;
      }
      return null;
    },
  },
]);
console.log('TEST');
const AuthenticatedApp = () => {
  const { activeUser } = useActiveUser();

  console.log('AUTHENTICATED APP');
  if (!activeUser) {
    return <RouterProvider router={loginRouter} />;
  }

  return <RouterProvider router={router} />;
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthenticatedApp />
  </React.StrictMode>
);
