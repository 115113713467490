// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactFlowNodeType, ReactFlowNodeTypes } from '../types/ReactFlowNode/ReactFlowNode';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import NodeTemplate from './NodeTemplate';

interface CustomNodeProps extends NodeProps {
    data: { label: string; type: ReactFlowNodeType; question?: string };
}

const StartNode = (props: CustomNodeProps) => {
    return (
        <>
            <NodeTemplate  active={props.data.active} node={props}>
                
            </NodeTemplate>
            <Handle type="source" position={Position.Bottom} />
            
        </>
    );
};

export default StartNode;
