export default {
  translation: {
    'Split Lines': 'Käsittele rivikohtaisesti',
    'Split the lines of the voucher.': 'Käsittele kuitin rivit erikseen',
    'Voucher Interpretation': 'Kuitin tulkinta',
    'Keyword Account Filter': 'Kontekstin kirjanpitotili',
    'Keyword Tag Filter': 'Kontekstin avainsana',
    'Your Contexts': 'Kontekstisi',
    'Your Flows': 'Päätöspuusi',
    Contexts: 'Kontekstit',
    Flows: 'Päätöspuut',
    Assistants: 'Assistentit',
    Home: 'Koti',
    Any: 'Mikä tahansa',
    None: 'Ei mikään',
    'Any keyword': 'Mikä tahansa avainsana',
    'Bookkeeping Account in Context': 'Käytä kontekstin kirjanpitotiliä',
    'Reference Context': 'Viitekonteksti',
    'Input Node': 'Alkupiste',
    'User Node': 'Kysymys ihmiselle',
    'Alert Node': 'Hälytys',
    Assistant: 'Tekoäly',
    'Programmable Node': 'Koodi',
    Rename: 'Nimeä uudelleen',
    'Edit Description': 'Muokkaa kuvailua',
    Properties: 'Ominaisuudet',
    Delete: 'Poista',
    Untitled: 'Otsikollinen',
    Name: 'Nimi',
    Cancel: 'Peruuta',
    'User Feedback': 'Palaute',
    Output: 'Tulos',
    'Output Node': 'Tulostin',
    Alert: 'Huomio',
    'Split Lines Start': 'Rivikohtainen käsittely',
    'Split Lines End': 'Tositekohtainen käsittely',
    Journal: 'Päiväkirja',
    'General Ledger': 'Pääkirja',
    Dimensions: 'Seurantakohteet',
    Date: 'Päivämäärä',
    Description: 'Kuvaus',
    Amount: 'Summa',
    Account: 'Tili',
    Balance: 'Saldo',
    'Sort by date:': 'Järjestä päivämäärän mukaan:',
    'Oldest first': 'Vanhimmat ensin',
    'Newest first': 'Uusimmat ensin',
    'Filter by dimension': 'Suodata seurantakohteen mukaan',
  },
};
