// @ts-nocheck

import { Dashboard } from '../api/types';
import useActiveUser from './useActiveUser';
import useSyncedDocument from './useSyncedDocument';

const useFlowMetadata = ({ flowId }): [
    Dashboard,
    React.Dispatch<React.SetStateAction<Dashboard>>,

] => {
    const {activeUser } = useActiveUser();
    return useSyncedDocument({ defaultValue: {
        keywordMapId: "ye",
    }, format: "Map", type: 'flow', docId: flowId});
}

export default useFlowMetadata;
