// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactFlowNodeType, ReactFlowNodeTypes } from '../types/ReactFlowNode/ReactFlowNode';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import NodeTemplate from './NodeTemplate';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

interface CustomNodeProps extends NodeProps {
    data: { label: string; type: ReactFlowNodeType; question?: string; active?: boolean };
}

const SplitLinesNode = (props: CustomNodeProps) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <NodeTemplate active={props.data.active} node={props} iconRotation={Math.PI}>

               
            </NodeTemplate>

            <Handle type="source" position={Position.Bottom} />
            <Handle type="target" position={Position.Top} />
        </>
    );
};

export default SplitLinesNode;
