import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import HeaderBar, { CurrentPages } from '../header-bar/HeaderBar';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';

import {
  deserializeChartOfAccounts,
  ChartOfAccounts,
  serializeAccount,
} from '@floness/accounting/browser';

async function parallelForEach<T>(
  items: T[],
  limit: number,
  asyncFn: (item: T, index: number) => Promise<void>
): Promise<void> {
  const executing: Set<Promise<void>> = new Set();

  return new Promise((resolve, reject) => {
    let counter = 0;
    let completed = 0;
    let total = items.length;
    let rejected = false;

    const runNext = () => {
      asyncFn(items[counter], counter)
        .then(() => {
          completed++;
          // console.log("parallelForEach", counter, completed, total)
          if (rejected) {
            // do nothing :)
          } else if (counter < total) {
            runNext();
          } else if (completed == total) {
            resolve();
          }
        })
        .catch((error) => {
          rejected = true;
          reject(error);
        });

      counter++;
    };

    for (let i = 0; i < items.length && i < limit; i++) {
      runNext();
    }
  });
}

// New component
const ChartOfAccountsList: React.FC<{
  accounts: Account[];
  expandedAccounts: Set<string>;
  toggleExpand: (accountId: string) => void;
  handleDrop: ((sourceId: string, destId: string) => void) | null;
}> = ({ accounts, expandedAccounts, toggleExpand, handleDrop }) => {
  const [accountPostings, setAccountPostings] = useState<Record<string, Posting[]>>({});

  const [activeOrganisation, setActiveOrganisation] = useActiveOrganisation();

  const navigate = useNavigate();

  const fetchPostings = async (accountId: string) => {
    try {
      setAccountPostings((prev) => ({
        ...prev,
        [accountId]: [],
      }));
      const response = await fetch(`/api/accounting/postings?accountId=${accountId}`, {
        headers: {
          'X-Organization': activeOrganisation.organizationId,
        },
      });
      const postings = await response.json();

      const bankTransactionReferences = {};

      postings.forEach((posting) => {
        const bankTransactionReference = posting.voucher.voucherReferences.find(
          (ref) => ref.type == 'bankTransaction'
        );

        if (bankTransactionReference) {
          if (!bankTransactionReferences[bankTransactionReference.value])
            bankTransactionReferences[bankTransactionReference.value] = [];
          bankTransactionReferences[bankTransactionReference.value].push(posting);
        }
      });

      const ids = Object.keys(bankTransactionReferences);

      const bankTransactionResponse = await fetch(
        `/api/accounting/bankTransactions?ids=${encodeURIComponent(ids.join(','))}`,
        {
          headers: {
            'X-Organization': activeOrganisation.organizationId,
          },
        }
      );
      const bankTransactions = await bankTransactionResponse.json();

      bankTransactions.forEach((transaction) => {
        const { id } = transaction;

        if (bankTransactionReferences[id])
          bankTransactionReferences[id].forEach(
            (posting) => (posting.bankTransaction = transaction)
          );
      });

      postings.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });
      setAccountPostings((prev) => ({
        ...prev,
        [accountId]: postings,
      }));
    } catch (error) {
      console.error('Failed to fetch postings:', error);
    }
  };

  const renderSubgroups = (acc: Account, depth: number) => {
    if (!expandedAccounts.has(acc.id)) {
      return null;
    }

    if (acc.number) {
      if (!accountPostings[acc.id]) {
        fetchPostings(acc.id);
      }

      return (
        <div>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="25%">Date</TableCell>
                <TableCell width="25%">Description</TableCell>
                <TableCell width="25%" align="right">
                  Amount
                </TableCell>
                <TableCell width="25%">Bank Transaction Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountPostings[acc.id]?.map((posting, index) => {
                const runningBalance = accountPostings[acc.id]
                  .slice(0, index + 1)
                  .reduce((sum, p) => sum + p.amount, 0);

                return (
                  <TableRow
                    key={posting.id}
                    sx={{
                      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/ledger/vouchers/${posting.voucher.id}`)}
                  >
                    <TableCell width="25%">
                      {new Date(posting.timestamp).toLocaleDateString()}
                    </TableCell>
                    <TableCell width="25%">
                      {posting.description || posting.voucher.description}
                    </TableCell>
                    <TableCell width="25%" align="right">
                      {(posting.amount / 100).toFixed(2)}
                    </TableCell>
                    <TableCell width="25%">
                      {posting.bankTransaction?.remittanceInformation}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    }

    return (
      <div>
        {acc.subgroups?.map((subgroup) => (
          <React.Fragment key={subgroup.id}>
            <AccountItem
              acc={subgroup}
              depth={depth + 1}
              renderSubgroups={(acc, depth) => renderSubgroups(acc, depth)}
            />
          </React.Fragment>
        ))}
        {acc.accounts?.map((subAccount) => (
          <AccountItem
            key={subAccount.id}
            acc={subAccount}
            depth={depth + 1}
            renderSubgroups={(acc, depth) => renderSubgroups(acc, depth)}
          />
        ))}
      </div>
    );
  };

  const AccountItem = ({ acc, depth, renderSubgroups }) => {
    const hasChildren = true;
    const isExpanded = expandedAccounts.has(acc.id);

    let isDragging = false;
    let ref = null;

    if (handleDrop) {
      const [{ isDragging: dragging }, drag] = useDrag(() => ({
        type: 'ACCOUNT',
        item: { id: acc.id },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        canDrag: () => true,
      }));

      const [{ isOver }, drop] = useDrop(() => ({
        accept: 'ACCOUNT',
        drop: (item: { id: string }) => {
          if (!item.dropped) {
            handleDrop(item.id, acc.id);
            item.dropped = true;
          }
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
        }),
      }));

      isDragging = dragging;
      ref = (node) => drag(drop(node));
    }

    return (
      <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, width: '100%' }}>
        <Grid item xs={12} container sx={{ width: '100%' }}>
          <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
            {hasChildren && (
              <IconButton
                size="small"
                onClick={() => toggleExpand(acc.id)}
                sx={{ mr: 1, position: 'absolute' }}
              >
                {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            )}
            <Box sx={{ pl: depth * 2, marginLeft: '40px' }}>
              <Typography>
                <Typography component="span" variant="caption" color="textSecondary" sx={{ mr: 1 }}>
                  {acc.number ? acc.number : `${acc.minAccountNumber} - ${acc.maxAccountNumber}`}
                </Typography>
                {acc.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            {Number.isFinite(acc.balance) && (
              <Typography>{(acc.balance / 100).toFixed(2)}</Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontSize: 8 }}>
              {acc.tags?.join(', ')}
              {acc.vats?.map((v) => JSON.stringify(v)).join(', ')}
            </Typography>
          </Grid>
        </Grid>
        {renderSubgroups && renderSubgroups(acc, depth)}
      </div>
    );
  };

  return (
    <>
      {accounts.map((account) => (
        <AccountItem
          key={account.id}
          acc={account}
          depth={1}
          renderSubgroups={(acc, depth) => renderSubgroups(acc, depth)}
        />
      ))}
    </>
  );
};

export default ChartOfAccountsList;
