// @ts-nocheck

import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import getNodeTypes from '../getNodeTypes';
import getIconClass from '../../style/getIconClass';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { useEffect, useState } from 'react';

const NodeTemplate = ({ children, active, onClick, node, onlyIcon, iconRotation: targetIconRotation }) => {
    const { t, i18n } = useTranslation();

    const [iconRotation, setIconRotation] = useState(targetIconRotation);

    useEffect(() => {
        if(!Number.isFinite(targetIconRotation)) {
            setIconRotation(0);
            return;
        }

        let rotation = iconRotation || 0;
        if(rotation != targetIconRotation) {
            while(Math.abs(rotation % (2 * Math.PI) - targetIconRotation) > 0.01) {
                rotation += Math.PI / 2
            }

        }

        setIconRotation(rotation);
    }, [targetIconRotation]);

    const nodeTypes = getNodeTypes();
    const nodeType = nodeTypes.find((nodeType) => nodeType.type === node.type);
    const IconClass = getIconClass(nodeType?.defaultIcon);
    
    return (
        <div
            style={{
                backgroundColor: 'white',
                color: 'black',
                padding: '10px',
                border: '0.05rem solid black',
                borderRadius: '0.5rem',
                fontWeight: 700,
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
                position: 'relative',
                paddingRight: onlyIcon ? "10px" :'30px',
                
            }}
            onClick={onClick}
        >
            {active && (
                <Box sx={{ position: 'absolute', top: '0', right: '0', bottom: 0, left: 0 }}>
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                </Box>
            )}

            <Box sx={{ textAlign: 'left' }}onClick={onClick}>
                <Box
                    bgcolor={'primary.main'}
                    sx={{
                        display: 'inline-flex',
                        // background: 'blue',
                        width: '25px',
                        height: '25px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '15px',
                    }}
                >
                    <IconClass
                        
                        sx={{ color: 'white', display: 'inline-flex', justifySelf: 'center',
                            transform: iconRotation ? `rotate(${iconRotation}rad)` : 'none',
                            transitionProperty: "transform",
  transitionDuration: "0.5s"


                         }}
                        fontSize={'20px'}
                    ></IconClass>{' '}
                </Box>
                {!onlyIcon && (
                    <Typography
                        sx={{ display: 'inline', position: 'relative', top: '-3px', left: '5px' }}
                        variant="body2"
                    >
                        {t(node.data.label)}
                    </Typography>
                )}
            </Box>
            {!onlyIcon && (
                <>
                    {children || []}
                    {active && (
                        <div
                            style={{
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                right: '-30px',
                                top: '0px',
                                bottom: '0px',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress size={20}></CircularProgress>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default NodeTemplate;
