export default {
  translation: {
    key: 'value',

    'Group Input Node': 'Group Input Node',
    'Group Output Node': 'Group Output Node',
    'Group Node': 'Group Node',
    'Group Instance Node': 'Group Instance Node',
    'Alert Node': 'Alert Node',
    'Input Node': 'Input Node',
    'Output Node': 'Output Node',

    Home: 'Home',
    Tasks: 'Tasks',
    Customers: 'Customers',
    'Chart of Accounts': 'Chart of Accounts',
    Reports: 'Reports',
    Flows: 'Flows',
    Contexts: 'Contexts',
    Assistants: 'Assistants',
    History: 'History',
    'Organisation settings': 'Organisation settings',
    'Provide Feedback': 'Provide Feedback',
    Topic: 'Topic',
    Feedback: 'Feedback',
    Rating: 'Rating',
    Cancel: 'Cancel',
    Submit: 'Submit',
    Journal: 'Journal',
    'General Ledger': 'General Ledger',
    Dimensions: 'Dimensions',
    Date: 'Date',
    Description: 'Description',
    Amount: 'Amount',
    Account: 'Account',
    Balance: 'Balance',
    'Sort by date:': 'Sort by date:',
    'Oldest first': 'Oldest first',
    'Newest first': 'Newest first',
    'Filter by dimension': 'Filter by dimension',
  },
};
