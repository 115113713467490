// @ts-nocheck

import debounce from 'lodash/debounce';
import * as Y from 'yjs';
import { useCallback, useEffect, useState, useRef } from 'react';
import { applyEdgeChanges, type Edge, type OnEdgesChange } from 'reactflow';
import { saveToS3 } from './saveJson';
import useYDoc from './useYDoc';
import useSyncedDocument from './useSyncedDocument';

function useNodesStateSynced({
    flowId: id,
}): [
    Edge[],
    React.Dispatch<React.SetStateAction<Edge[]>>,
    OnEdgesChange,
    React.MutableRefObject<Y.Map<Edge> | null>,
] {


    const [edges, setEdges] = useSyncedDocument({
        defaultValue: [],
        type: 'edges',
        docId: id,
    });

    const [nodes, setNodes, loaded] = useSyncedDocument({
        defaultValue: [],
        type: 'nodes',
        docId: id,
    });

    const onNodesChanges: OnNodesChange = useCallback(
        (changes) => {
            const nextNodes = applyNodeChanges(changes, nodes);
            setNodes(nextNodes);

            for (const change of changes) {
                if (change.type === 'remove' && nodesMapRef.current!.has(change.id)) {
                    const deletedNode = nodes.find((node) => node.id == change.id);
                    const connectedEdges = getConnectedEdges([deletedNode], [edges]);

                    setEdges(edges.filter((edge) => !connectedEdges.includes(edge)));
                }
            }
        },
        [edges]
    );

    const setValue = useCallback( (params) => {
        setNodes(params);
    }, [setNodes])

    return [nodes, setValue, onNodesChanges, loaded];
}

export default useNodesStateSynced;
