// @ts-nocheck

import { Box, Button, CircularProgress, Container, Divider, 
Paper, Table, 
TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import HeaderBar, { CurrentPages } from "../header-bar/HeaderBar"
import { Fragment, useEffect, useState } from "react"

import DoneIcon from '@mui/icons-material/Done';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import useDashboardStateSynced from "../collaboration/useDashboardStateSynced";
import { useNavigate } from "react-router-dom";
import useActiveOrganisation from "../collaboration/useActiveOrganisation";
import useActiveUser from "../collaboration/useActiveUser";



const OrganisationSettingsPage = (props) =>{

    const [activeOrganisation, setActiveOrganisation] = useActiveOrganisation();
    const { activeUser } = useActiveUser()

    const [organisationMembers, setOrganisationMembers] = useState([])
    const [apiKey, setApiKey] = useState("")
    useEffect(() => {
        const fetchOrganisationMembers = async () => {
        const response = await fetch(`/api/organizations/${activeOrganisation.organizationId}/members`)  
            const data = await response.json()

            data.sort((a, b) => b.createdAt - a.createdAt)
            setOrganisationMembers(data)
        }
        fetchOrganisationMembers()

    }, [setOrganisationMembers])

    useEffect(() => {
        const fetchApiKey = async () => {
        const response = await fetch(`/api/organizations/${activeOrganisation.organizationId}/apiKey`)  
            const data = await response.json()

            setApiKey(data.apiKey)
        }
        fetchApiKey()

    }, [setApiKey])

    const [newUserId, setNewUserId] = useState("")

   const addUser = async () => {

    setNewUserId("")

            const id = newUserId.trim()

            if(id.length == 0) {
                alert("empty user id");
                return
            }

            setNewUserId("")
            
            const response = await fetch(`/api/organizations/${activeOrganisation.organizationId}/userRights/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ rights: "member" })
            } )

            const member = await response.json()

            setOrganisationMembers([...organisationMembers, member])
            

        }

    

    return (
        <Box>
            <HeaderBar currentPage={CurrentPages.Settings} />
    <Container maxWidth="md" sx={{marginTop: "30px"}} >
        <Typography variant="h5" sx={{marginTop: "10px"}}>Organisation settings</Typography>
        <Divider sx={{marginTop: "10px", marginBottom :"40px"}} />

        <Typography variant="h6" sx={{marginTop: "10px"}}>Credentials</Typography>

        <TableContainer component={Paper}  sx={{marginBottom: "40px"}} >
            <Table>
                <TableHead sx={{fontWeight: "bold"  }}>
                    <TableRow>
                    <TableCell sx={{fontWeight: "bold"}}>Credential type</TableCell>
                    <TableCell sx={{fontWeight: "bold"}}>Value</TableCell>
                    
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                        <TableRow>
                            <TableCell>API key</TableCell>
                            <TableCell sx={{fontFamily: "courier new"}}>{apiKey}</TableCell>
                            
                        </TableRow>
                    
                </TableBody>
            </Table>
            </TableContainer>

        <Typography variant="h6" sx={{marginTop: "10px"}}>Organisation members</Typography>

        <TableContainer component={Paper}  >
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>User identifier</TableCell>
                    <TableCell>Rights</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {organisationMembers.map((member, index) => (
                        <TableRow key={index+"frewfew"}>
                            <TableCell>{member.name}</TableCell>
                            <TableCell>{member.userId}</TableCell>
                            <TableCell>{member.rights}</TableCell>
                        </TableRow>
                    ))}

                    <TableRow>
                            <TableCell></TableCell>
                            <TableCell><TextField
                                placeholder="User identifier"
                                sx={{width: "100%"}}
                                value={newUserId}
                                onChange={event  => setNewUserId(event.target.value)}
                                onKeyDown={ async event => {
                                    if(event.key === "Enter"){
                                        // create new member
                            
                                        addUser()
                                    }
                            
                                }}
                            
                            ></TextField></TableCell>
                            <TableCell><Button onClick={() => addUser()}>Add</Button></TableCell>
                        </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
        </Container>
    
        </Box>

    )

}

export default OrganisationSettingsPage
