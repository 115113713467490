// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactFlowNodeType, ReactFlowNodeTypes } from '../types/ReactFlowNode/ReactFlowNode';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import NodeTemplate from './NodeTemplate';
import { Box, TextField } from '@mui/material';

interface CustomNodeProps extends NodeProps {
    data: { label: string; type: ReactFlowNodeType; question?: string };
}

const GroupOutputNode = (props: CustomNodeProps) => {
    return (
        <>
            <NodeTemplate  active={props.data.active} node={props}>
            <Box>
                    <TextField
                        size={'small'}
                        variant={'standard'}
                        onBlur={() => {}}
                        value={props.data.value}
                        placeholder='Value'
                        onKeyDown={(e) => {
                            e.stopPropagation();
                        }}
                        onKeyUp={(e) => {
                            e.stopPropagation();
                        }}
                        sx={{ width: '320px', marginTop: '5px' }}
                        onChange={(e) => {
                            e.stopPropagation()
                            props.data.value = e.target.value;
                            setNodes([...nodes]);

                            // setNodes([...nodes]);
                        }}
                    >
                        {' '}
                    </TextField>
                </Box>
            </NodeTemplate>
            
            <Handle type="target" position={Position.Top} />
            
        </>
    );
};

export default GroupOutputNode;
