// @ts-nocheck

import { KeywordMapData } from '../api/types';

import useSyncedDocument from '../collaboration/useSyncedDocument';

const defaultValue: KeywordMapData = {
    mappings: [],
    industries: [],
    chartOfAccountsIdentifier: "Tilikarttaa ei ole valittu",
}

const useKeywordMapStateSynced = ({ keywordMapId }:{ keywordMapId: string}): [
    KeywordMapData,
    React.Dispatch<React.SetStateAction<KeywordMapData>>,
] => {
    return useSyncedDocument({ defaultValue, format: "Map", type: 'keywords', docId: keywordMapId});

};

export default useKeywordMapStateSynced;
