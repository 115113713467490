import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Chip, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import HeaderBar, { CurrentPages } from '../header-bar/HeaderBar';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { deserializeChartOfAccounts, ChartOfAccounts, serializeAccount } from "@floness/accounting/browser"

import ChartOfAccountsList from './ChartOfAccountsList';

interface Customer {
  customerId: string;
  customerName: string;
  tags: string[];
}


const ChartOfAccountsPage: React.FC = () => {
  const { t } = useTranslation();
  const [chartOfAccounts, setChartOfAccounts] = useState<ChartOfAccounts>({tree: [], list: []});
  

  const [activeOrganisation, setActiveOrganisation] = useActiveOrganisation()
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const fetchChartOfAccounts = async () => {
      if (isLoading || loaded) return;
      
      setIsLoading(true);
      try {
        const response = await fetch('/api/accounting/chart-of-accounts', {
          headers: {
            'X-Organization': activeOrganisation.organizationId,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch chart of accounts');
        }
        const data = await response.json();

        const { list, tree } = deserializeChartOfAccounts(data.accounts)
        setChartOfAccounts({ list, tree });
        setLoaded(true);
      } catch (error) {
        console.error('Error fetching chart of accounts:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false);
      }
    };

    fetchChartOfAccounts();
  }, [activeOrganisation]);

  const saveChartOfAccounts = async (newChartOfAccounts) => {
    try {
      const response = await fetch('/api/accounting/chart-of-accounts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Organization': activeOrganisation.organizationId,
        },
        body: JSON.stringify(newChartOfAccounts.list.map(a => serializeAccount(a))),
      });
      if (!response.ok) {
        throw new Error('Failed to save chart of accounts');
      }
    } catch (error) {
      console.error('Error saving chart of accounts:', error);
    }
  };

  const [expandedAccounts, setExpandedAccounts] = useState<Set<string>>(new Set());

  const toggleExpand = (accountId: string) => {
    setExpandedAccounts(prev => {
      const newSet = new Set(prev);
      if (newSet.has(accountId)) {
        newSet.delete(accountId);
      } else {
        newSet.add(accountId);
      }
      return newSet;
    });
  };

  const handleDrop = (sourceId: string, destId: string) => {
    const movedAccount = chartOfAccounts.list.find(a => a.id === sourceId);
    const destinationAccount = chartOfAccounts.list.find(a => a.id === destId);

    if (movedAccount?.parent?.accounts) {
      const accountIndex = movedAccount.parent.accounts.findIndex(a => a.id === movedAccount.id);
      if (accountIndex >= 0) {
        movedAccount.parent.accounts.splice(accountIndex, 1);
      }
    }
    if (movedAccount?.parent?.subgroups) {
      const subgroupIndex = movedAccount.parent.subgroups.findIndex(a => a.id === movedAccount.id);
      if (subgroupIndex >= 0) {
        movedAccount.parent.subgroups.splice(subgroupIndex, 1);
      }
    }

    movedAccount.parent = destinationAccount;

    if (movedAccount.number) {
      destinationAccount.accounts = destinationAccount.accounts || [];
      destinationAccount.accounts.push(movedAccount);
      destinationAccount.accounts?.sort((a, b) => parseInt(a.number) - parseInt(b.number));
    } else {
      destinationAccount.subgroups = destinationAccount.subgroups || [];
      destinationAccount.subgroups.push(movedAccount);
      destinationAccount.subgroups?.sort((a, b) => a.minAccountNumber - b.minAccountNumber);
    }
    const newChartOfAccounts = {...chartOfAccounts}
    setChartOfAccounts(newChartOfAccounts);

    saveChartOfAccounts(newChartOfAccounts)
  };

  return (
    <>
      <HeaderBar currentPage={CurrentPages.ChartOfAccounts} setCurrentPage={() => {}} />
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('Chart of Accounts')}
        </Typography>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} container>
              <Grid item xs={9}><Typography variant="subtitle1" fontWeight="bold">{t('Name')}</Typography></Grid>
              <Grid item xs={3}><Typography variant="subtitle1" fontWeight="bold">{t('Tags')}</Typography></Grid>
            </Grid>
            
            <ChartOfAccountsList 
              accounts={chartOfAccounts.tree}
              expandedAccounts={expandedAccounts}
              toggleExpand={toggleExpand}
              handleDrop={handleDrop}
            />
          </Grid>
        </DndProvider>
      </Box>
    </>
  );
};

export default ChartOfAccountsPage;
