// @ts-nocheck

import { memo, useEffect, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactFlowNodeType, ReactFlowNodeTypes } from '../types/ReactFlowNode/ReactFlowNode';
import CodeIcon from '@mui/icons-material/Code';
import NodeTemplate from './NodeTemplate';
import { useParams } from 'react-router-dom';
import useNodesStateSynced from '../../collaboration/useNodesStateSynced';
import useEdgesStateSynced from '../../collaboration/useEdgesStateSynced';
import { Box, TextField, Typography } from '@mui/material';

interface CustomNodeProps extends NodeProps {
    data: { label: string; type: ReactFlowNodeType; question?: string };
}

const context = { a: 10, b: 20 };

const ProgrammableNode = (props: CustomNodeProps) => {

    
    const [editingCode, setEditingCode] = useState(false);

    const { flowId } = useParams();
    const [nodes, setNodes] = useNodesStateSynced({ flowId });
    const [edges, setEdges] = useEdgesStateSynced({ flowId });


    const evaluateCode = (evaluatedCode) => {
        return new Function("data", "node", "nodes", "edges", evaluatedCode)({}, props, nodes, edges)
    }

    useEffect(()=> {
        try {
            const result = evaluateCode(props.data.code);
            
            setCodeError(false);
            setCodeResult(result)
        } catch(error) {
            setCodeError(error.toString());
        }

    }, [])

    const [codeError, setCodeError] = useState(false);

    const [code, setCode] = useState(props.data.code);

    const [codeResult, setCodeResult] = useState("");

    useEffect(() => {
        setCode(props.data.code);
    }, [props.data.code])

    return (
        <>
            <NodeTemplate  active={props.data.active} node={props}>
            <Box sx={ {textAlign: "left", marginTop: "10px"}}>
                    <Typography
                        variant="body2"
                        sx={{ fontSize: '10px', fontFamily: 'Courier New' }}
                    >
                        {`function processNode({ data, node, nodes, edges }) {`}
                    </Typography>
                    <TextField
                        multiline
                        autoFocus={true}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        inputProps={{
                            style: {
                                fontSize: '10px',
                                lineHeight: '12px',
                                fontFamily: 'Courier New',
                            },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                            marginLeft: '20px',
                            padding: 0,
                            width: 'calc(100% - 20px)',
                            fontFamily: 'Courier New',
                        }}
                        size={'small'}
                        value={code}
                        variant="outlined"
                        onChange={(event) => {
                            const newCode = event.target.value;
                            setCode(newCode)
                            try {
                                const result = evaluateCode(newCode);
                                console.log(result)
                                
                                props.data.code = newCode;
                                setNodes([...nodes]);
                                setCodeError(false);
                                setCodeResult(result)
                            } catch(error) {
                                setCodeError(error.toString());
                            }


                            
                            // setNewOption(event.target.value);

                        }}
                    ></TextField>
                    <Typography
                        variant="body2"
                        sx={{ fontSize: '10px', fontFamily: 'Courier New' }}
                    >
                        {`}`}
                    </Typography>
                    {codeError?  <Typography
                        variant="body2"
                        sx={{color: "red", fontSize: '10px', fontFamily: 'Courier New' }}
                    >
                        {codeError}
                    </Typography> :<Typography
                        variant="body2"
                        sx={{color: "green", fontSize: '10px', fontFamily: 'Courier New' }}
                    >
                        {`processNode({}, node, nodes, edges) => ${codeResult}`}
                    </Typography> }

                    

                   
                </Box>



            </NodeTemplate>
            
            <Handle type="source" position={Position.Bottom} />
            <Handle type="target" position={Position.Top} />
        </>
    );
};

export default ProgrammableNode;
