import { useEffect, useState, useMemo } from 'react';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Container,
  Box,
  Tabs,
  Tab,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  deserializeChartOfAccounts,
  ChartOfAccounts,
  serializeAccount,
} from '@floness/accounting/browser';
import HeaderBar, { CurrentPages } from '../header-bar/HeaderBar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

interface Account {
  id: string;
  name: string;
  number: string;
  // ... other account properties if needed
}

interface Posting {
  id: string;
  amount: number;
  accountId: string;
  timestamp: number;
  voucher: {
    description: string;
    // ... other voucher properties if needed
  };
}

type ViewType = 'journal' | 'general-ledger' | 'dimensions';

interface AccountBalance {
  accountId: string;
  postings: Posting[];
  balance: number;
}

interface DimensionEntry {
  dimension: string;
  postings: Posting[];
  balance: number;
}

type SortDirection = 'asc' | 'desc';

function JournalView({
  postings,
  accounts,
  onRowClick,
  sortDirection,
}: {
  postings: Posting[];
  accounts: Account[];
  onRowClick: (posting: Posting) => void;
  sortDirection: SortDirection;
}) {
  const { t, i18n } = useTranslation();
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'EUR',
      }),
    [i18n.language]
  );

  const sortedPostings = useMemo(() => {
    return [...postings].sort((a, b) => {
      const comparison = b.timestamp - a.timestamp;
      return sortDirection === 'desc' ? comparison : -comparison;
    });
  }, [postings, sortDirection]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="journal table">
        <TableHead>
          <TableRow>
            <TableCell>{t('Date')}</TableCell>
            <TableCell>{t('Description')}</TableCell>
            <TableCell align="right">{t('Amount')}</TableCell>
            <TableCell>{t('Account')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPostings.map((posting) => {
            const account = accounts.find((a) => a.id === posting.accountId);
            return (
              <TableRow
                key={posting.id}
                onClick={() => onRowClick(posting)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                }}
              >
                <TableCell>{new Date(posting.timestamp).toLocaleDateString()}</TableCell>
                <TableCell>{posting.voucher.description}</TableCell>
                <TableCell align="right">{formatter.format(posting.amount / 100)}</TableCell>
                <TableCell>
                  {account ? `${account.number} ${account.name}` : posting.accountId}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function GeneralLedgerView({
  postings,
  accounts,
  onRowClick,
  sortDirection,
}: {
  postings: Posting[];
  accounts: Account[];
  onRowClick: (posting: Posting) => void;
  sortDirection: SortDirection;
}) {
  const { t, i18n } = useTranslation();
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'EUR',
      }),
    [i18n.language]
  );

  const accountBalances = useMemo(() => {
    const balances: Record<string, AccountBalance> = {};

    accounts.forEach((account) => {
      balances[account.id] = {
        accountId: account.id,
        postings: [],
        balance: 0,
      };
    });

    postings.forEach((posting) => {
      if (balances[posting.accountId]) {
        balances[posting.accountId].postings.push(posting);
        balances[posting.accountId].balance += posting.amount;
      }
    });

    return Object.values(balances).filter((b) => b.postings.length > 0);
  }, [postings, accounts]);

  return (
    <TableContainer component={Paper}>
      {accountBalances.map((accountBalance) => {
        const account = accounts.find((a) => a.id === accountBalance.accountId);
        return (
          <Box key={accountBalance.accountId} sx={{ marginBottom: 3 }}>
            <Typography variant="h6" sx={{ padding: 2 }}>
              {account ? `${account.number} ${account.name}` : accountBalance.accountId}
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="25%">{t('Date')}</TableCell>
                  <TableCell width="25%">{t('Description')}</TableCell>
                  <TableCell width="25%" align="right">
                    {t('Amount')}
                  </TableCell>
                  <TableCell width="25%" align="right">
                    {t('Balance')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountBalance.postings
                  .sort((a, b) => {
                    const comparison = b.timestamp - a.timestamp;
                    return sortDirection === 'desc' ? comparison : -comparison;
                  })
                  .map((posting, index) => {
                    const runningBalance = accountBalance.postings
                      .slice(0, index + 1)
                      .reduce((sum, p) => sum + p.amount, 0);

                    return (
                      <TableRow
                        key={posting.id}
                        onClick={() => onRowClick(posting)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                        }}
                      >
                        <TableCell width="25%">
                          {new Date(posting.timestamp).toLocaleDateString()}
                        </TableCell>
                        <TableCell width="25%">{posting.voucher.description}</TableCell>
                        <TableCell width="25%" align="right">
                          {formatter.format(posting.amount / 100)}
                        </TableCell>
                        <TableCell width="25%" align="right">
                          {formatter.format(runningBalance / 100)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        );
      })}
    </TableContainer>
  );
}

function DimensionsView({
  postings,
  accounts,
  onRowClick,
  sortDirection,
}: {
  postings: Posting[];
  accounts: Account[];
  onRowClick: (posting: Posting) => void;
  sortDirection: SortDirection;
}) {
  const { t, i18n } = useTranslation();
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'EUR',
      }),
    [i18n.language]
  );

  // This is a placeholder - you'll need to implement dimension tracking based on your data structure
  const dimensionEntries = useMemo(() => {
    // Group by dimension (this is an example - adjust according to your actual dimension structure)
    const entries: Record<string, DimensionEntry> = {};

    postings.forEach((posting) => {
      if (posting.dimension) {
        // You'll need to add dimension to your Posting interface
        if (!entries[posting.dimension]) {
          entries[posting.dimension] = {
            dimension: posting.dimension,
            postings: [],
            balance: 0,
          };
        }
        entries[posting.dimension].postings.push(posting);
        entries[posting.dimension].balance += posting.amount;
      }
    });

    return Object.values(entries);
  }, [postings]);

  return (
    <TableContainer component={Paper}>
      {dimensionEntries.map((entry) => (
        <Box key={entry.dimension} sx={{ marginBottom: 3 }}>
          <Typography variant="h6" sx={{ padding: 2 }}>
            {entry.dimension}
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('Date')}</TableCell>
                <TableCell>{t('Description')}</TableCell>
                <TableCell>{t('Account')}</TableCell>
                <TableCell align="right">{t('Amount')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entry.postings
                .sort((a, b) => {
                  const comparison = b.timestamp - a.timestamp;
                  return sortDirection === 'desc' ? comparison : -comparison;
                })
                .map((posting) => {
                  const account = accounts.find((a) => a.id === posting.accountId);
                  return (
                    <TableRow
                      key={posting.id}
                      onClick={() => onRowClick(posting)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                      }}
                    >
                      <TableCell>{new Date(posting.timestamp).toLocaleDateString()}</TableCell>
                      <TableCell>{posting.voucher.description}</TableCell>
                      <TableCell>
                        {account ? `${account.number} ${account.name}` : posting.accountId}
                      </TableCell>
                      <TableCell align="right">{formatter.format(posting.amount / 100)}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      ))}
    </TableContainer>
  );
}

export function LedgerPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [postings, setPostings] = useState<Posting[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [activeOrganisation] = useActiveOrganisation();
  const [viewType, setViewType] = useState<ViewType>('journal');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [selectedDimension, setSelectedDimension] = useState<string | null>(null);

  const availableDimensions = useMemo(() => {
    const dimensions = new Set<string>();
    postings.forEach((posting) => {
      if (posting.dimension) {
        dimensions.add(posting.dimension);
      }
    });
    return Array.from(dimensions);
  }, [postings]);

  const filteredPostings = useMemo(() => {
    if (!selectedDimension) return postings;
    return postings.filter((posting) => posting.dimension === selectedDimension);
  }, [postings, selectedDimension]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch both postings and chart of accounts
        const [postingsResponse, accountsResponse] = await Promise.all([
          fetch('/api/accounting/postings', {
            headers: {
              'X-Organization': activeOrganisation.organizationId,
            },
          }),
          fetch('/api/accounting/chart-of-accounts', {
            headers: {
              'X-Organization': activeOrganisation.organizationId,
            },
          }),
        ]);

        if (!accountsResponse.ok) {
          throw new Error('Failed to fetch chart of accounts');
        }

        const [postingsData, accountsData] = await Promise.all([
          postingsResponse.json(),
          accountsResponse.json(),
        ]);

        const { list } = deserializeChartOfAccounts(accountsData.accounts);
        setAccounts(list);
        setPostings(postingsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [activeOrganisation.organizationId]);

  const handleRowClick = (posting: Posting) => {
    navigate(`/ledger/vouchers/${posting.voucher.id}`);
  };

  return (
    <>
      <HeaderBar currentPage={CurrentPages.Ledger} setCurrentPage={() => {}} />
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('Ledger')}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
          <Tabs value={viewType} onChange={(_, newValue) => setViewType(newValue)}>
            <Tab value="journal" label={t('Journal')} />
            <Tab value="general-ledger" label={t('General Ledger')} />
            <Tab value="dimensions" label={t('Dimensions')} />
          </Tabs>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Autocomplete
              sx={{ width: 200 }}
              options={availableDimensions}
              value={selectedDimension}
              onChange={(_, newValue) => setSelectedDimension(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" label={t('Filter by dimension')} />
              )}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Typography variant="body2" color="text.secondary">
                {t('Sort by date:')}{' '}
                {sortDirection === 'asc' ? t('Oldest first') : t('Newest first')}
              </Typography>
              <IconButton
                onClick={() => setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))}
              >
                {sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
              </IconButton>
            </Box>
          </Box>
        </Box>

        {viewType === 'journal' && (
          <JournalView
            postings={filteredPostings}
            accounts={accounts}
            onRowClick={handleRowClick}
            sortDirection={sortDirection}
          />
        )}

        {viewType === 'general-ledger' && (
          <GeneralLedgerView
            postings={filteredPostings}
            accounts={accounts}
            onRowClick={handleRowClick}
            sortDirection={sortDirection}
          />
        )}

        {viewType === 'dimensions' && (
          <DimensionsView
            postings={filteredPostings}
            accounts={accounts}
            onRowClick={handleRowClick}
            sortDirection={sortDirection}
          />
        )}
      </Box>
    </>
  );
}
