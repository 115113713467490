// @ts-nocheck

import debounce from 'lodash/debounce';
import * as Y from 'yjs';
import { useCallback, useEffect, useState, useRef } from 'react';
import { applyEdgeChanges, type Edge, type OnEdgesChange } from 'reactflow';
import { saveToS3 } from './saveJson';
import useYDoc from './useYDoc';
import useSyncedDocument from './useSyncedDocument';

function useEdgesStateSynced({
    flowId: id,
}): [
    Edge[],
    React.Dispatch<React.SetStateAction<Edge[]>>,
    OnEdgesChange,
    React.MutableRefObject<Y.Map<Edge> | null>,
] {
    const [edges, setEdges, edgesLoaded] = useSyncedDocument({
        defaultValue: [],
        type: 'edges',
        docId: id,
    });

    const onEdgesChange: OnEdgesChange = useCallback((changes) => {
        console.log('onEdgesChange', 'edges');
        const nextEdges = applyEdgeChanges(changes, edges);

        setEdges(nextEdges);
    }, []);



    return [edges, (params) => {
        console.log("setting edges")
        setEdges(params)
    }, onEdgesChange, edgesLoaded];
}

export default useEdgesStateSynced;
