import { KeywordMapData } from '../api/types';

import useSyncedDocument from '../collaboration/useSyncedDocument';

const useThreadStateSynced = ({ threadId }:{ threadId: string}): [
    KeywordMapData,
    React.Dispatch<React.SetStateAction<KeywordMapData>>,
] => {
    return useSyncedDocument({ defaultValue: {
        assistantId: "",
        thread: null
        
    }, type: 'thread', docId: threadId});

};

export default useThreadStateSynced;
