// @ts-nocheck
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress } from '@mui/material';

import { fetchUser } from '../collaboration/useActiveUser';

const SignUpDialog = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password }),
        credentials: 'include', // This ensures cookies are sent with the request
      });
      const data = await response.json();
      if (data.success) {

        fetchUser()
        // Handle successful signup
        // document.cookie = `session=${data.sessionId}; path=/; secure; samesite=strict`; // Set session cookie
        onClose();
      } else {
        // Handle unsuccessful signup
        console.error('Signup failed:', data.message);
      }
    } catch (error) {
      console.error('Signup error:', error);
      // Handle error (e.g., show error message)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Sign Up</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress size={24} color="inherit" />
              Signing Up...
            </>
          ) : (
            'Sign Up'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignUpDialog;
