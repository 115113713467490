// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactFlowNodeType, ReactFlowNodeTypes } from '../types/ReactFlowNode/ReactFlowNode';
import PersonIcon from '@mui/icons-material/Person';
import NodeTemplate from './NodeTemplate';
import getIconClass from '../../style/getIconClass';
import getNodeTypes from '../getNodeTypes';
import { useParams } from 'react-router-dom';
import useNodesStateSynced from '../../collaboration/useNodesStateSynced';
import { Box, TextField } from '@mui/material';
import QuestionNode from './QuestionNode';

interface CustomNodeProps extends NodeProps {
    data: { label: string; type: ReactFlowNodeType; question?: string };
}

const UserNode = (props: CustomNodeProps) => {
    return <QuestionNode {...props} />;
};

export default UserNode;
