// @ts-nocheck

import { Context, useCallback, useContext, useEffect, useState, createContext } from 'react';
import * as Y from 'yjs';
import { YSweetProvider, createYjsProvider } from '@y-sweet/client';
import { ClientToken } from '@y-sweet/sdk';
import { useParams } from 'react-router-dom';
import getYDoc from './getYDoc';
import { set } from 'lodash';

type YDocContextType = {
    yDoc: Y.Doc | null;
    docId: string | null;
} | null;

const YDocContext = createContext(null) as Context<YDocContextType>;

const ydocs = {};
const fetchingYdocs = {};

function getYDoc(id: string, onLoaded: () => any): { yDoc: Y.Doc, loaded: boolean, docId: string } {
    if(ydocs[id]) return { loaded: true, docId: id, yDoc: ydocs[id] }

    initYDoc(id).then(() => {
        
        onLoaded(id)
    })

    const yDoc = new Y.Doc();
    
    return { loaded: false, docId: id, yDoc }
}

async function initYDoc(id: string): Promise<Y.Doc> {
    if(fetchingYdocs[id]) {
        await new Promise(resolve => fetchingYdocs[id].push(resolve))

        return ydocs[id]
    }

    fetchingYdocs[id] = []

    const initClientToken: ClientToken = await (
        await fetch(`/client-token/${id}`)
    ).json();

    const ydoc = new Y.Doc();
    const provider = createYjsProvider(ydoc, initClientToken, { disableBc: true });

    await new Promise(resolve => provider.on('synced', () => {
        ydocs[id] = ydoc
        resolve()
    }))

    return ydocs[id]
}

export default getYDoc
